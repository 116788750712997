import SEO from "../../components/seo"
import ElternInfoContentHeader from "../../components/elterninfo/elterninfo_contentHeader"
import ElternInfoSubmenu from "../../components/elterninfo/elterninfo_submenu"
import React from "react"
import TermineMargin from "../../components/termine-margin"
import Layout from "../../components/layout"
import ElterninfoSteckbrief_JohannaSchmidt from "../../components/elterninfo/elterninfo_steckbrief_johannaschmidt"
import ElterninfoSteckbrief_NinaWehmeier from "../../components/elterninfo/elterninfo_steckbrief_ninawehmeier"


const steckbriefe = () => (
  <Layout>
    <SEO title="Schulsozialarbeit"/>

    <section id="content_2_schule">
      <ElternInfoContentHeader headline="SCHULSOZIALARBEIT" motto="UNSERE SOZIALARBEITER:INNEN"/>
      <ElternInfoSubmenu/>

      <article id="con_2_rechts">

        <ElterninfoSteckbrief_JohannaSchmidt/>
        <hr />
        <ElterninfoSteckbrief_NinaWehmeier />
      </article>

        <TermineMargin/>
    </section>
  </Layout>
)

export default steckbriefe
