import React from "react"

import {StaticImage} from "gatsby-plugin-image"

const ElterninfoSteckbrief_JohannaSchmidt = () => (
  <section id="content_1">

      <h3 className="small_sub"><span
        style={{ color: "#007c34" }}>Johanna Schmidt</span>
      </h3>

    <StaticImage style={{margin: "10px", float: "right"}} src={"../../images/Elternbrief_Schulsozialarbeit-Johanna Schmidt.jpg"}
                 width={180}
                 alt={"Bild von Johanna Schmidt"} />

    <p className="text"><span style={{fontWeight: "bold"}}>Liebe Eltern,</span></p>

      <p className="text">
        mein Name ist Johanna Schmidt und ich arbeite seit diesem
        Schuljahr als Schulsozialarbeiterin an der Grundschule Löhne-Bahnhof.
        </p>
    <p className="text" style={{marginTop: "10px"}}>
        Schulsozialarbeit ist ein Beratungs- und Begleitungsangebot für
        Kinder, Erziehungsberechtigte und Familien, sowie Lehrer:innen
        und Mitarbeitende der OGS. Ein Gespräch mit mir ist freiwillig und vertraulich.
      </p>

      <p className="text">Schwerpunkte meiner Arbeit sind:<br/>
        <ul>
          <li>Beratung bei Erziehungsfragen und schulischen Schwierigkeiten</li>
          <li>Kontakt zu außerschulischen Förderstellen</li>
          <li>Vermittlung passender Unterstützungsangebote</li>
          <li>Beratung zu Leistungen aus dem Bildungspaket (BuT): Klassenfahrten,
            Lernförderung, Teilnahme am Mittagessen in der OGS, Mitgliedschaften in
            Vereinen etc.</li>
          <li>Durchführung von Gruppen- und Klassenangeboten zum Sozialen Lernen</li>
          <li>Vermittlung von Freizeitangeboten der Stadt Löhne (Kinder- und
            Jugendzentren, Ferienspiele etc.)</li>
        </ul>

        Ihnen und Ihren Kindern stehe ich unterstützend zur Seite.
      </p>

    <p className="text" style={{marginTop: "10px"}}>
      Sie erreichen mich:<br/>
      telefonisch unter der Rufnummer: 0151 22472914<br/>
      Montags- donnerstags: 08:00-12:30 Uhr<br/>
      Oder per E-Mail unter: <a href="mailto:j.schmidt@loehne.de">j.schmidt@loehne.de</a><br/>
    </p>

    <p className="text" style={{marginTop: "10px"}}>
      Ich wünsche Ihnen und Ihrer Familie ein gutes Schuljahr!
    </p>

    <p className="text" style={{marginTop: "10px"}}>
      Liebe Grüße<br/>
      - J.S. Schmidt
    </p>
  </section>
)

export default ElterninfoSteckbrief_JohannaSchmidt
