import React from "react"

import {StaticImage} from "gatsby-plugin-image"

const ElterninfoSteckbrief_NinaWehmeier = () => (
  <section id="content_1">

      <h3 className="small_sub"><span
        style={{ color: "#007c34" }}>Nina Wehmeier</span>
      </h3>

    <StaticImage style={{margin: "10px", float: "right"}} src={"../../images/Vorstellung Nina Wehmeier.jpg"}
                 width={180}
                 alt={"Bild von Johanna Schmidt"} />

    <p className="text"><span style={{fontWeight: "bold"}}>Liebe Eltern,</span></p>

      <p className="text">
        mein Name ist Nina Wehmeier. Ich bin die neue Fachkraft für das multiprofessionelle Team (MPT).
        Die Zielgruppen meiner Arbeit sind die <strong>neu zugewanderten</strong> SchülerInnen und deren Familien.
        </p>
    <p className="text" style={{marginTop: "10px"}}>
      Als MPT-Kraft stehe ich Ihnen gerne bei <strong>sozialen und behördlichen Angelegenheiten</strong> und dem Stellen von <strong>Bildung und Teilhabe-Anträgen</strong> zur Seite.
      Gerne helfe ich Ihnen bei der <strong>Vermittlung an verschiedene Fachstellen</strong>, wie beispielsweise Institute für Nachhilfe oder diverse Arztpraxen.
      Während des Schulalltages versuche ich aufkommende <strong>Krisen und/oder Konflikte</strong>, die Ihre Kinder betreffen, zu bewältigen.
      Sie können mich ebenfalls kontaktieren, wenn es um die <strong>Freizeitgestaltung</strong> ihrer Kinder geht. Ich informiere Sie gerne über die möglichen Angebote,
      die die Jugendkunstschule, das Kinder- und Jugendzentrum Riff, das Stadtteilzentrum Raps, die Musikschule, die Stadtbücherei, Schwimmbäder und diverse (Sport-)vereine bieten.
      </p>

    <p className="text" style={{marginTop: "10px"}}>
      Meine Einsatzorte sind die Goethe-Realschule, die Grundschule Melbergen-Wittel und die Grundschule Löhne-Bahnhof.
      Aktuell bin ich montags und mittwochs an der Realschule, dienstags in Melbergen und donnerstags in Löhne-Bahnhof anzutreffen. <br/>
      Trotz der verschiedenen Standorte bin ich montags bis freitags unter meiner Dienstnummer <span style={{fontWeight: "bold"}}>(0171 6214615)</span> oder Mailadresse (<a href={"mailto:n.wehmeier@loehne.de"}>n.wehmeier@loehne.de</a>) für Sie erreichbar.
    </p>

    <p className="text" style={{marginTop: "10px"}}>
      Beste Grüße<br/>
      - Nina Wehmeier
    </p>
  </section>
)

export default ElterninfoSteckbrief_NinaWehmeier
